<template>
    <el-dialog
        :title="$t('clients.add_comment')"
        :visible.sync="modalAddComment"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <div v-loading="$wait.is('loading.add_comment')" class="flex justify-center my-5 px-12">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="w-full">
                <el-form-item :label="$t('clients.comment')" prop="content" class="w-full">
                    <el-input v-model="formData.content" type="textarea" :rows="3" />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('loading.add_comment')" @click="add">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalAddComment: {
            type:    Boolean,
            default: false,
        },
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            formData: {
                content: '',
            },
            rules: {
                content: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('loading.add_comment');

            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientId}/comments`, {
                author:  this.$store.state.user.user.welcome_title,
                content: this.formData.content,
            });

            this.$wait.end('loading.add_comment');
            this.$emit('refresh');
            this.closeModal();
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
